<template>
  <div id="ccc" class="hello">
      <div id="DDDDDDDDD">DDDDDDDDD</div>

    <h1>{{ msg }}</h1>
    <h2>Counter: {{counter}}</h2>

    <h3>Installed CLI Plugins</h3>
    <ul>
      <li>
        <a
          href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel"
          target="_blank"
          rel="noopener"
          >babel</a
        >
      </li>
      <li>
        <a
          href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint"
          target="_blank"
          rel="noopener"
          >eslint</a
        >
      </li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li>
        <a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a>
      </li>
      <li>
        <a href="https://forum.vuejs.org" target="_blank" rel="noopener"
          >Forum</a
        >
      </li>
      <li>
        <a href="https://chat.vuejs.org" target="_blank" rel="noopener"
          >Community Chat</a
        >
      </li>
      <li>
        <a href="https://twitter.com/vuejs" target="_blank" rel="noopener"
          >Twitter</a
        >
      </li>
      <li>
        <a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a>
      </li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li>
        <a href="https://router.vuejs.org" target="_blank" rel="noopener"
          >vue-router</a
        >
      </li>
      <li>
        <a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a>
      </li>
      <li>
        <a
          href="https://github.com/vuejs/vue-devtools#vue-devtools"
          target="_blank"
          rel="noopener"
          >vue-devtools</a
        >
      </li>
      <li>
        <a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener"
          >vue-loader</a
        >
      </li>
      <li>
        <a
          href="https://github.com/vuejs/awesome-vue"
          target="_blank"
          rel="noopener"
          >awesome-vue</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
var viewer      = null;
var designer    = null;

export default {
  name: "HelloWorld",
  props: {
    msg: String
  },

  data() {
    return {
      counter: 0,
    };
  },

  created() {
    //	if (this.$route.params.relatorio)
    //    	this.relatorio = this.$route.params.relatorio;
    //	else
    //    	this.relatorio = this.$route.params.relatorio;
		// Chave 2021
    // Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkcgIvwL0jnpsDqRpWg5FI5kt2G7A0tYIcUygBh1sPs7plofUOqPB1a4HBIXJB621mau2oiAIj+ysU7gKUXfjn/D5BocmduNB+ZMiDGPxFrAp3PoD0nYNkkWh8r7gBZ1v/JZSXGE3bQDrCQCNSy6mgby+iFAMV8/PuZ1z77U+Xz3fkpbm6MYQXYp3cQooLGLUti7k1TFWrnawT0iEEDJ2iRcU9wLqn2g9UiWesEZtKwI/UmEI2T7nv5NbgV+CHguu6QU4WWzFpIgW+3LUnKCT/vCDY+ymzgycw9A9+HFSzARiPzgOaAuQYrFDpzhXV+ZeX31AxWlnzjDWqpfluygSNPtGul5gyNt2CEoJD1Yom0VN9fvRonYsMsimkFFx2AwyVpPcs+JfVBtpPbTcZscnzUdmiIvxv8Gcin6sNSibM6in/uUKFt3bVgW/XeMYa7MLGF53kvBSwi78poUDigA2n12SmghLR0AHxyEDIgZGOTbNI33GWu7ZsPBeUdGu55R8w="

    // Chave 2022
		//Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHn0s4gy0Fr5YoUZ9V00Y0igCSFQzwEqYBh/N77k4f0fWXTHW5rqeBNLkaurJDenJ9o97TyqHs9HfvINK18Uwzsc/bG01Rq+x3H3Rf+g7AY92gvWmp7VA2Uxa30Q97f61siWz2dE5kdBVcCnSFzC6awE74JzDcJMj8OuxplqB1CYcpoPcOjKy1PiATlC3UsBaLEXsok1xxtRMQ283r282tkh8XQitsxtTczAJBxijuJNfziYhci2jResWXK51ygOOEbVAxmpflujkJ8oEVHkOA/CjX6bGx05pNZ6oSIu9H8deF94MyqIwcdeirCe60GbIQByQtLimfxbIZnO35X3fs/94av0ODfELqrQEpLrpU6FNeHttvlMc5UVrT4K+8lPbqR8Hq0PFWmFrbVIYSi7tAVFMMe2D1C59NWyLu3AkrD3No7YhLVh7LV0Tttr/8FrcZ8xirBPcMZCIGrRIesrHxOsZH2V8t/t0GXCnLLAWX+TNvdNXkB8cF2y9ZXf1enI064yE5dwMs2fQ0yOUG/xornE"
  },

  mounted() {
    setInterval(() => this.counter++, 1000);

    this.createViewer();

  },

  methods: {

    createViewer() {

			//const urlParams = new URLSearchParams(window.location.search);
			//const cod_relatorio = urlParams.get('cod_relatorio');
			//console.log("cod_relatorio", cod_relatorio);

			// Specify necessary options for the viewer
			var options = new Stimulsoft.Viewer.StiViewerOptions();

//console.log("-----", this.$refs.Rel1.height);
//console.log("-----", document.body.clientHeight);

 			options.height = "90%";
// 			//options.width = "1000px";

// //			console.log(options.appearance);

// 			options.appearance.allowTouchZoom = true;
// 			//options.appearance.backgroundColor = t {name: "White", _a: 255, _r: 255, _g: 255, _b: 255},
// 			options.appearance.bookmarksPrint = false
// 			options.appearance.bookmarksTreeWidth = 180
// 			options.appearance.chartRenderType = 3
// 			options.appearance.datePickerFirstDayOfWeek = 0
 			options.appearance.fullScreenMode = false
// 			options.appearance.htmlRenderMode = 3
// 			options.appearance.interfaceType = 0
// 			options.appearance.openExportedReportWindow = "_blank"
// 			options.appearance.openLinksWindow = "_blank"
// 			options.appearance.pageAlignment = 1
// 			//options.appearance.pageBorderColor = t {name: "Gray", _a: 255, _r: 128, _g: 128, _b: 128},
// 			options.appearance.parametersPanelColumnsCount = 2
 			options.appearance.parametersPanelDateFormat = "dd/MM/YYYY"
// 			//options.appearance.parametersPanelMaxHeight = 300
// 			options.appearance.parametersPanelPosition = 0
// 			options.appearance.parametersPanelSortDataItems = true
// 			options.appearance.reportDisplayMode = 3
// 			options.appearance.rightToLeft = false
 			options.appearance.scrollbarsMode = true
// 			options.appearance.showPageShadow = false
 			options.appearance.showTooltips = true
 			options.appearance.showTooltipsHelp = false
			options.toolbar.showAboutButton 	= false;
			options.toolbar.showOpenButton		= false;
// 			options.toolbar	= 	{	showDesignButton 	: false,
// 									printDestination 	: Stimulsoft.Viewer.StiPrintDestination.Direct,
// 									showAboutButton 	: false,
// 									viewMode 			: Stimulsoft.Viewer.StiWebViewMode.Continuous,
// 									ShowSendEmailButton : true,
// 									//borderColor 		: Stimulsoft.System.Drawing.Color.navy,
// 									//showPrintButton 	: false,
// 									//showViewModeButton: true,
// 									//zoom 				: 50;
// 								}

// 			options.exports = {	showExportDialog: true,
// 								showExportToCsv: true,
// 								showExportToDbf: false,
// 								showExportToDif: false,
// 								showExportToDocument: true,
// 								showExportToExcel: false,
// 								showExportToExcel2007: true,
// 								showExportToExcelXml: false,
// 								showExportToHtml: true,
// 								showExportToHtml5: true,
// 								showExportToImageBmp: false,
// 								showExportToImageGif: false,
// 								showExportToImageJpeg: false,
// 								showExportToImageMetafile: false,
// 								showExportToImagePcx: false,
// 								showExportToImagePng: false,
// 								showExportToImageSvg: false,
// 								showExportToImageSvgz: false,
// 								showExportToImageTiff: false,
// 								showExportToMht: false,
// 								showExportToOpenDocumentCalc: true,
// 								showExportToOpenDocumentWriter: true,
// 								showExportToPdf: true,
// 								showExportToPowerPoint: true,
// 								showExportToRtf: false,
// 								showExportToSylk: false,
// 								showExportToText: true,
// 								showExportToWord2007: true,
// 								showExportToXml: false,
// 								showExportToXps: false,
// 								storeExportSettings: true,
// 							}

			// Create an instance of the viewer
			viewer = new Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);

			// Add the design button event
			viewer.onDesignReport = function (e) {
				this.visible = false;
				if (designer == null) createDesigner();
				designer.visible = true;
				designer.report = e.report;
			};

			// viewer.onReady = function () {
			// 	alert('1');
			// 	var customButton = viewer.SmallButton("customButton", "Custom Button", "emptyImage");
			// 	customButton.image.src = "https://www.stimulsoft.com/images/logo_16.png";
			// 	customButton.action = function () {
			// 		alert("Custom Button Event");
			// 	}
			// 	var toolbarTable = viewer.controls.toolbar.firstChild.firstChild;
			// 	var buttonsTable = toolbarTable.rows[0].firstChild.firstChild;
			// 	var customButtonCell = buttonsTable.rows[0].insertCell(0);
			// 	customButtonCell.appendChild(customButton);
			// }
			viewer.renderHtml("DDDDDDDDD");

		},

		createDesigner() {
			var options = new Stimulsoft.Designer.StiDesignerOptions();
			options.appearance.fullScreenMode = true;
			options.appearance.htmlRenderMode = Stimulsoft.Report.Export.StiHtmlExportMode.Table;

			// Create an instance of the designer
			designer = new Stimulsoft.Designer.StiDesigner(options, "StiDesigner", false);

			// Add the exit menu item event
			designer.onExit = function (e) {
				this.visible = false;
				viewer.visible = true;
			}

			designer.renderHtml(this.windowRef);
		},

		setReport(reportObject) {
//console.log("reportObject 1", this.relatorio);
			// Forcibly show process indicator
			viewer.showProcessIndicator();
			reportObject = this.relatorio;

			// Timeout need for immediate display loading report indicator
			setTimeout(function () {
				// Create a new report instance
				var report = new Stimulsoft.Report.StiReport();
				// Load reports from JSON object
				report.load(reportObject);

				// Assign the report to the viewer
				viewer.report = report;

			}, 50);
		},

		// Set report button style
		setReportButton(button) {
			for (var reportName in reportsCollection) {
				var reportButton = document.getElementById(reportName);
				reportButton.isSelected = reportButton == button;
				reportButton.className = reportButton.isSelected ? "ReportButton Selected" : "ReportButton";
			}
		},

		// Load first report after the page is loaded
		// onBodyLoad() {
		// 	createViewer();
		// 	selectedButton = document.getElementById("Relatorio_31");
		// 	//console.log(selectedButton);
		// 	onButtonClick(selectedButton, Relatorio_31);
		// },

		// Demo Report button events
		// ---

		onButtonClick(button, reportObject) {
			selectedButton.className = "ReportButton";
			button.className = "ReportButton Selected";
			selectedButton = button;
			setReport(reportObject);
		},

		onButtonMouseOver(button) {
			if (button != selectedButton) button.className = "ReportButton Over";
		},

		onButtonMouseOut(button) {
			if (button != selectedButton) button.className = "ReportButton";
		}

  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
