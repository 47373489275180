import Vue from "vue";
import { API } from "../../services/API"

function initialState() {
  return {
    dados            : [],
    cod_pessoa_aux   : null,
    cod_opor_aux     : null,
    item_aux         : null,
    documentacao_aux : null
  }
}

var vm_store_ModalEnvioDocumentos = new Vue({
  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },

    async Abre_Documentos(cod_cliente,
                          cliente_cod_pessoa_socio,
                          cliente_cod_pessoa_conjuge,
                          cod_empreendvenda) {

      const resposta = await API.get("fase_documentos", {
        params: {
          cod_cliente                 : cod_cliente ?? null,
          cliente_cod_pessoa_socio    : cliente_cod_pessoa_socio ?? null,
          cliente_cod_pessoa_conjuge  : cliente_cod_pessoa_conjuge ?? null,
          cod_empreendvenda           : cod_empreendvenda ?? null
        },
      });
      if (resposta) {
        var ls_JSON = JSON.parse(JSON.stringify(resposta.data.result));
        this.dados = ls_JSON;
        // this.cod_opor = cod_opor;
        // this.cod_fase = cod_fase;
      }
      //this.$router.push('enviodocumentos');
      //document.getElementById("mymodal").click();
      //this.$forceUpdate();      
    },

    async AprovaGedPut(body) {
      const resp = await API.put(`/situacao_ged/${body.cod_ged}`,JSON.stringify(body));
      const { data } = resp;

      return data;
    },


    async AprovaEstornaDocumentacao(p_body) {
      const resposta = await API.post("aprovar_estornar_documentacao", p_body );
      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          return resposta
        }
        else
          return resposta;
      }
    }    
  
  }
    
})

export default vm_store_ModalEnvioDocumentos

