<template>
  <div>
    <div v-for="(item, index) in mergedContents" :key="index">
      <img v-if="item.type === 'image'" :src="item.content" alt="Imagem" />
      <canvas v-else-if="item.type === 'pdf'" ref="canvas" style="border: 1px solid black;"></canvas>
    </div>
  </div>
</template>

<script>
// // Import the library
// import * as pdfjsLib from "pdfjs-dist";
// // Import worker
// import pdfJSWorkerURL from "pdfjs-dist/build/pdf.worker?url";
// // Import whatever modules you need
// import { PDFSinglePageViewer, EventBus, PDFLinkService, PDFFindController, PDFScriptingManager } from "pdfjs-dist/web/pdf_viewer";
// // Bind the worker for later use 
// window.pdfJSWorkerURL = pdfJSWorkerURL;

export default {
  props: ['pdfUrls', 'imageUrls'],

  data() {
    return {
      mergedContents: []
    };
  },

  created() {
    console.log('CREATED');
    // pdfjsLib.GlobalWorkerOptions.workerSrc = pdfJSWorkerURL;
  },

  mounted() {
    this.loadContents();
  },

  methods: {
    async loadContents() {
      for (let i = 0; i < this.pdfUrls.length; i++) {
        await this.loadPdf(this.pdfUrls[i]);
      }
      for (let i = 0; i < this.imageUrls.length; i++) {
        this.mergedContents.push({ type: 'image', content: this.imageUrls[i] });
      }
    },
    async loadPdf(url) {
      const loadingTask = pdfjsLib.getDocument(url);
      const pdf = await loadingTask.promise;
      const pageNum = 1; // Página do PDF a ser renderizada

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: ctx,
        viewport: viewport
      };
      await page.render(renderContext).promise;
      this.mergedContents.push({ type: 'pdf', content: canvas.toDataURL() });
    }
  }
};
</script>

<style scoped>
/* Estilos específicos para este componente, se necessário */
</style>